// middleware/requestMiddleware.js

import RequestClient from '~/utils/request.js';

const requestClient = new RequestClient(true); // Enable notifications

export default async function useRequest(method, endpoint, data, key, cache = false) {
  const { data: result, error } = await useAsyncData(
    endpoint, // Use endpoint as a unique key for caching
    () => requestClient[method](endpoint, data, key, cache), // Call the appropriate method
    {
      server: true, // Prioritize server-side fetching
      lazy: false,  // Fetch immediately
    }
  );

  // Unwrap the Refs for proper access to the data and error
  return {
    data: result.value, // Unwrapping the Ref here
    error: error?.value, // Also unwrapping the error Ref
  };
}
